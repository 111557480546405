import { Main } from './Main'
import { Login } from './pages/login/Login'
import { authStore } from './store/auth/authStore'
import './styles/styles.scss'

function App() {
  const token = authStore((state) => state.token)
  return <>{token ? <Main /> : <Login />}</>
}

export default App
