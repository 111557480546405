import React, { useState } from 'react'
import { Redirect, useParams } from 'react-router-dom'
import { useGetForm } from '../../hooks/useGetForm'
import { SpinnerFullHeight } from '../../components/spinner/SpinnerFullHeight'
import { collectAnswers } from '../../utils/collectAnswers'
import { post } from '../../service/API'
import { BASE_URL, COLOR_NOT_SELECTED, COLOR_PRIMARY, INFORMATION, TEXT_FREE } from '../../constants'
import { TextFree } from '../../components/questions/TextFree'
import { validateAnswers } from '../../utils/validateAnswers'
import { authStore } from '../../store/auth/authStore'
import { Information } from '../../components/questions/Information'
import { dataStore } from '../../store/dataStore'
import { TextPrefilled } from '../../components/questions/TextPrefilled'
import { AnswerObj, QuestionType } from '../../Types'
import { ErrorView } from '../../components/error/ErrorView'

type Props = {
  dermFormId: string
  dataRegEvent: string

  imageId: string
}

export const DermEvaluationForm = ({ dermFormId, dataRegEvent, imageId }: Props) => {
  const { studyId, enrollmentId } = useParams() as { enrollmentId: string; studyId: string }

  const selectedSubject = dataStore((state) => state.selectedSubject)
  const user = authStore((state) => state.user)
  const setSelectedSubject = dataStore((state) => state.setSelectedSubject)

  const [answerArr, setAnswerArr] = useState<AnswerObj[]>([])
  const [errorsArr, setErrorHashMap] = useState<any>([])

  const { data, isError } = useGetForm(studyId, dermFormId)
  const questionArr: QuestionType[] = data && data.questions ? data.questions : []
  const [isSaving, setIsSaving] = useState<any>(false)
  const [submissionError, setSubmissionError] = useState<any>(null)

  if (isError) {
    return <ErrorView isError={isError} customMessage="The evaluation form could not be loaded"></ErrorView>
  }

  if (!selectedSubject) {
    return <Redirect to={'/' + studyId}></Redirect>
  }

  if (submissionError) {
    return (
      <ErrorView
        isError={{ message: submissionError.msg || 'Smart-trial did not accept the submission' }}
        customMessage="Your submission form was not successfull"
      ></ErrorView>
    )
  }

  if (!user.email) {
    return (
      <ErrorView
        isError={{ message: 'Assessor identification is missing' }}
        customMessage="The assessor identification is required. Please logout to reset your profile"
      ></ErrorView>
    )
  }

  if (!data) {
    return <SpinnerFullHeight></SpinnerFullHeight>
  }

  const selectItem = (answerValue, question: any) => {
    setAnswerArr((prevState) => {
      const updatedNewArr: AnswerObj[] = collectAnswers(answerValue, question, prevState)
      return updatedNewArr.concat([])
    })
  }

  const submitAnswers = () => {
    const dataEventRegistrationId = dataRegEvent
    const formId = dermFormId

    const errors = validateAnswers(answerArr, questionArr)

    if (errors.length > 0) {
      setErrorHashMap(errors)
      return
    }

    setIsSaving(true)

    const p =
      BASE_URL +
      `/api/answerForm?studyId=${studyId}&enrollmentId=${enrollmentId}&dataEventRegistrationId=${dataEventRegistrationId}&formId=${formId}`

    post(p, { answerObject: { answers: answerArr } })
      .then((response) => {
        setIsSaving(false)

        if (response.data === 200) {
          setSelectedSubject(null)
        } else {
          setSubmissionError(response.data)
        }
      })
      .catch((error) => {
        setIsSaving(false)
        console.log(' DermEvaluationForm > POST answer error = ', error)
        alert(error)
      })
  }

  return (
    <>
      {isSaving ? (
        <div className="container mt-6">
          <SpinnerFullHeight>
            <div className="row">
              <div className="col-12">
                <h3>Saving Evaluation</h3>
              </div>
            </div>
          </SpinnerFullHeight>
        </div>
      ) : (
        <>
          <div className="row" style={{}}>
            {questionArr.map((item: QuestionType, index) => {
              const showRules = item.showRules

              const hasError = errorsArr.indexOf(item._id) > -1 ? true : false

              if (showRules) {
                const shouldShow = showRules[0].possibilities.filter((pos: any) => {
                  const anyMatchs = answerArr.filter((answerObj: AnswerObj) => {
                    if (answerObj.possibilities && answerObj.question === showRules[0].question) {
                      return answerObj && answerObj.possibilities ? answerObj.possibilities.indexOf(pos) > -1 : false
                    } else {
                      return answerObj && answerObj.answerStrings ? answerObj.answerStrings.indexOf(pos) > -1 : false
                    }
                  })
                  return anyMatchs.length !== 0
                })

                if (shouldShow.length === 0) {
                  return null
                }
              }

              const answerToQuestionArr = answerArr.filter((answerObj: AnswerObj) => {
                return answerObj.question === item._id
              })

              if (item.type === INFORMATION) {
                return <Information key={index} data={item}></Information>
              }

              if (item.type === TEXT_FREE && item.exportLabel === 'REDERMID') {
                return (
                  <TextPrefilled
                    key={index}
                    hasError={hasError}
                    selectItem={selectItem}
                    question={item}
                    answerToQuestionArr={answerToQuestionArr}
                    prefilledValue={user.email}
                  />
                )
              }

              if (item.type === TEXT_FREE && item.exportLabel === 'REPHTID') {
                return (
                  <TextPrefilled
                    key={index}
                    hasError={hasError}
                    selectItem={selectItem}
                    question={item}
                    answerToQuestionArr={answerToQuestionArr}
                    prefilledValue={imageId}
                  />
                )
              }

              if (item.type === TEXT_FREE) {
                return <TextFree key={index} hasError={hasError} selectItem={selectItem} question={item}></TextFree>
              }

              return (
                <QuestionItem
                  key={index}
                  hasError={hasError}
                  selectItem={selectItem}
                  question={item}
                  answerToQuestionArr={answerToQuestionArr}
                ></QuestionItem>
              )
            })}
          </div>
          <div className="row" style={{}}>
            <div className="col-12 mb-6 d-flex  flex-column align-items-center">
              <div onClick={submitAnswers} className="btn btn-primary p-4 w-50 mt-4 pointer">
                <h5 className="mb-0 text-white">Submit answers</h5>
              </div>
              {errorsArr.length > 0 ? (
                <div className="col-12 mb-6 d-flex justify-content-center">
                  <p className="p-small mt-2 text-danger ">Please validate all question has been answred </p>
                </div>
              ) : null}
            </div>
          </div>
        </>
      )}
    </>
  )
}

type QuestionItemType = {
  question: any
  selectItem: (posibility, questionId) => void
  answerToQuestionArr: AnswerObj[]
  hasError: boolean
}

const QuestionItem = ({ selectItem, question, answerToQuestionArr, hasError }: QuestionItemType) => {
  const possibilities = question.possibilities || []
  const qTitle = question.question.da

  const answerToQuestion = answerToQuestionArr.length > 0 ? answerToQuestionArr[0] : null

  const hasSelection = answerToQuestion?.possibilities === undefined ? true : false

  const err = hasError && hasSelection

  return (
    <div className="col-12 py-4 bg-white mb-2" style={{ border: err ? '2px solid red' : 'none' }}>
      <p className="p-small text-muted">{question._id}</p>
      <h5>{qTitle}</h5>

      <div className="row d-flex" style={{}}>
        <div className="col-12 ">
          {possibilities.map((subItem, subIndex) => {
            const isSelected =
              answerToQuestion && answerToQuestion.possibilities
                ? answerToQuestion.possibilities.indexOf(subItem._id) > -1
                : false

            return (
              <SubItem
                key={subIndex}
                selectItem={selectItem}
                question={question}
                subItem={subItem}
                isSelected={isSelected}
              ></SubItem>
            )
          })}
        </div>

        {hasError && <div className="col-12">This question is required</div>}
      </div>
    </div>
  )
}

type SubItemProps = {
  subItem: any
  question: any
  selectItem: (posibility, questionId) => void
  isSelected: boolean
}

const SubItem = ({ subItem, selectItem, question, isSelected }: SubItemProps) => {
  const label = subItem.possibility.da
  const clickker = () => {
    //console.log(' DermEvaluationForm > subItem = ', subItem)
    selectItem(subItem._id, question)
  }

  return (
    <div
      onClick={clickker}
      className="btn btn-primary mb-1 mt-2 mr-1"
      style={{ background: isSelected ? COLOR_PRIMARY : COLOR_NOT_SELECTED }}
    >
      {label}
    </div>
  )
}
