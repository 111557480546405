import React, { FormEvent, useState } from 'react'
import './login-form.scss'
import { authStore, AuthStoreType } from '../../store/auth/authStore'

type Props = {}

export const Login: React.FC<Props> = () => {
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [error, setError] = useState('')

  const grantType = 'password'
  const login = authStore((state: AuthStoreType) => state.login)

  const handleFormSubmit = (event: FormEvent) => {
    event.preventDefault()

    login(password, username, grantType).then((res: any) => {
      console.log(' Login > res = ', res)

      /*if (code === 'users-1003') {
        reject('Invalid user credentials.');
      } else if (code === 'users-1009') {
        reject('Invalid refresh token');
      } else if (code === 'users-1010') {
        reject('Refresh token is expired.');
      } else if (code === 'users-1023') {
        reject('User is locked.');
      } else {
        reject(error);
      }*/

      if (res?.msg) {
        setError(res.msg)
        return
      }
    })
  }

  return (
    <div className="container h-100 login-page">
      <div className="row h-100" style={{}}>
        <div className="col-12 d-flex justify-content-center align-items-center">
          <form onSubmit={handleFormSubmit} style={{ width: 360 }}>
            <p className="mb-0 text-center">Welcome to the</p>
            <h3 className="mt-0 mb-4 text-center font-weight-bold ">Studies&Me COAK Dermview </h3>
            <div className="w-100 ">
              <label htmlFor="username">E-mail</label>
              <input
                id="username"
                type="text"
                placeholder="Username"
                autoComplete="username"
                value={username}
                name="username"
                onChange={(e) => setUsername(e.target.value)}
                style={{ borderRadius: 4, height: 48 }}
              />
            </div>
            <div>
              <label htmlFor="password">Password</label>
              <input
                id="password"
                type="password"
                autoComplete="current-password"
                placeholder="Password"
                value={password}
                name="password"
                onChange={(e) => setPassword(e.target.value)}
                style={{ borderRadius: 4, height: 48 }}
              />
              <div className=" ">
                <input
                  style={{ background: 'black', cursor: 'pointer', color: 'white', borderRadius: 4, height: 48 }}
                  type="submit"
                  value="Login"
                />
                <p className="mt-1">{error}</p>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}
