export const studyIds = [
  {
    title: 'Working - Copenhagen Actinic Keratosis Study',
    id: '61604fec45012731244949f1',
  },
  {
    title: 'test - Copenhagen Actinic Keratosis Study',
    id: '61b540c1273979401892fb2b',
  },
  {
    title: 'Copenhagen Actinic Keratosis Study, DermView test',
    id: '617a803ce2afe300bc72da3a',
  },
]
